@import '~antd/dist/antd.css';

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.avatar-uploader {
  width: 100%;
  height: auto !important;
}

.avatar-uploader .ant-upload {
  padding: 0px 0px 0px 0px !important;
}

.upload-image {
  transition: .5s ease;
  width: 100%;
  opacity: 1;
}

.upload-underlay {
  transition: .5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  opacity: 0;
}

.avatar-uploader:hover .upload-image {
  opacity: 0.3;
}

.avatar-uploader:hover .upload-underlay {
  opacity: 1;
}

.ant-image-img {
  object-fit: cover;
}
